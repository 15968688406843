
import { useState, useEffect } from "react";



const Status = ({type,info, active}) =>{
    const [open, setOpen] = useState();

    useEffect(()=>{
        setOpen(active?true:false);
    },[active]);

    return(
        <div className={`${type==0?'success':'error'} ${open?'active':''} status`}>
           <p className="info">{info}</p> 
        </div>
    )
}

export default Status;