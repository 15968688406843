import { useState } from "react";
import { LineArrow } from "../svgs";

const Pane = () =>{
    const [tabOpen, setTabOpen] = useState(false);
    return(
        <aside id="pane" className={`${tabOpen?'active':''}`}>
            <button id="pane-open-btn" className={`brdls active`} onClick={()=>{
                setTabOpen(!tabOpen);
            }}>
                <LineArrow />
            </button>
        </aside>
    )
}
export default Pane;