import { useState, useEffect } from "react";
import { UserContext } from "../../models/user";
import { loadStart } from "../../utils";

const Addr = ({addr, coin}) =>{
    const [copied, copy] = useState(false);
    

    useEffect(()=>{
        if(copied){
            try{
                window.navigator.clipboard.writeText(addr);
                setTimeout(()=>{
                    copy(false);
                },2000);
            } catch(err){
                alert("Error writing to clipboard");
                copy(false);
            }
        }
    },[copied]);

    return(
        <div className="dep-addr-cont">
            <p className="type">{coin}</p>
            <div className="dep-addr">
                <p className="dep-addr-txt">{addr}</p>
                <button className="dep-addr-copy brdls" onClick={()=>{
                    copy(true);
                }}>{`${copied?'Copied':'Copy'}`}</button>
            </div>
        </div>
    );
}


const Deposit = ({close}) =>{

    const [addr, setAddr] = useState();

    const Client = UserContext().user;

    useEffect(()=>{
        if(Client){
            Client.getWallets(data=>{
                console.log(data);
                if(data.status == 0){
                    let _tmp = [];
                    data.data.forEach(el => {
                        _tmp.unshift(<Addr
                            addr={el.address}
                            coin={`${el.coin} ${el.network}`}
                        />)
                    });
                    setAddr(_tmp);
                }
            },err=>{
                console.log(err);
            }
        )
            
        }
    }, [])



    return(
        <div className="dep auth-base">
            <div className='auth-cont dep-cont tr'>
                <p className="wi-head tr-head">Deposit</p>
                <p className="tr-pre">Make sure to deposit the correct token to the appropriate address.<br/>Sending the wrong token to an address will result in a permanent loss of the token.</p>
                <div className="body">
                   {addr}
                </div>
                <div className="tr-end">
                    <button className="tr-action brdls" onClick={close}>
                        Done
                    </button>
                </div>
            </div>
           
        </div>
    )
}

export default Deposit;