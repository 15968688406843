import {createContext, useContext, useState } from "react";

import User from "./user";

const LocalContext = createContext();
export const UserContext = () => useContext(LocalContext);

export const UserProvider = ({children, userId}) => {
    const [user] = useState(new User(userId));

    return(
        <LocalContext.Provider value={{user}}>
            {children}
        </LocalContext.Provider>
    )
}