import Layout from "../components/layout";

import '../styles/jackpot.css';

const Jackpot = () =>{
    return(
        <Layout>
            <div className="jk-main">
                <div className="jk-center"> 
                    <div className="jk-img"></div>
                    <p className="jk-tx">COMING SOON!</p>
                </div>
            </div>
        </Layout>
    )
}

export default Jackpot;