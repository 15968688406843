import { useState } from 'react';
import $ from 'jquery';
import config from '../../config';
import XenonValidate from '../../xenon/xenonValidate';

import { loadStart, loadEnd } from '../../utils';

import '../../styles/auth.css';
import Status from './status';

const Auth = () =>{

    const [status,setStatus] = useState({});
    const [statusActive, setStatusActive] = useState(false);
    const Login = () =>{
        const [em, setEm] = useState({
            valid : false,
            value : ''
        });

        const [pass, setPass] = useState({
            valid : false,
            value : ''
        });

        return(
            <div className='form'>
                <input className={`${em.valid?'valid':''} inp txt`} type='email' placeholder='Email' onChange={e=>{
                    setEm({
                        valid : XenonValidate.validateEmail(e.target.value)[0]?true:false,
                        value : e.target.value
                    })
                }}/>
                <input className={`${pass.valid?'valid':''} inp txt`} type='password' placeholder='Password' onChange={e=>{
                    setPass({
                        valid : XenonValidate.validatePassword(e.target.value)[0]?true:false,
                        value : e.target.value
                    })
                }}/>
                <br />
                <button className='inp btn brdls' disabled={!(em.valid && pass.valid)} onClick={()=>{
                    let fd = new FormData();
                    fd.append('email',em.value);
                    fd.append('pass',pass.value);
                    loadStart();
                    $.ajax({
                        url : `${config.apiUrl}/api/v1/auth/login-user`,
                        dataType : 'json',
                        method : "POST",
                        data : fd,
                        dataType : 'json',
                        processData : false,
                        contentType: false,
                        cache: false
                    })
                    .done(data=>{
                        loadEnd();
                        console.log(data);
                        setStatus({
                            type : data.status,
                            info : data.message,
                        });
                        setStatusActive(true);
                        setTimeout(() => {
                            setStatusActive(false);
                        }, 2000);
                        if(data.status==0){
                            localStorage.setItem('tin',data.token);
                            window.location.href = '/';
                        }
                    })
                    .fail(err=>{
                        loadEnd();
                        // alert('err');
                        console.log(err);
                        setStatus({
                            type : 1,
                            info : "An error occurred",
                        });
                        setStatusActive(true);
                        setTimeout(() => {
                            setStatusActive(false);
                        }, 2000);
                    })
                }}>Login</button>
            </div>
        )
    }

    const SignUp = () =>{
        const [em, setEm] = useState({
            valid : false,
            value : ''
        });

        const [pass, setPass] = useState({
            valid : false,
            value : ''
        });

        const [cpass, setCPass] = useState({
            valid : false,
            value : ''
        })
        return(
            <div className='form'>
                <input className={`${em.valid?'valid':''} inp txt`} type='email' placeholder='Email' onChange={e=>{
                    setEm({
                        valid : XenonValidate.validateEmail(e.target.value)[0]?true:false,
                        value : e.target.value
                    })
                }}/>
                <input className={`${pass.valid?'valid':''} inp txt`} type='text' placeholder='Confirm password' onChange={e=>{
                    setPass({
                        valid : XenonValidate.validatePassword(e.target.value)[0]?true:false,
                        value : e.target.value
                    })
                }}/>
                <input className={`${cpass.valid?'valid':''} inp txt`} type='text' placeholder='Confirm password' onChange={e=>{
                    setCPass({
                        valid : e.target.value == pass.value?true:false,
                        value : e.target.value
                    })
                }}/>
                <br />
                <button className='inp btn brdls' disabled={!(em.valid&&pass.valid&&cpass.valid)} onClick={()=>{
                    let fd = new FormData();
                    fd.append('email',em.value);
                    fd.append('pass',pass.value);
                    fd.append('cpass',cpass.value);
                    loadStart();
                    $.ajax({
                        url : `${config.apiUrl}/api/v1/auth/new-user`,
                        dataType : 'json',
                        method : "POST",
                        data : fd,
                        dataType : 'json',
                        processData : false,
                        contentType: false,
                        cache: false
                    })
                    .done(data=>{
                        loadEnd();
                        console.log(data);
                        setStatus({
                            type : data.status,
                            info : data.message,
                        });
                        setStatusActive(true);
                        setTimeout(() => {
                            setStatusActive(false);
                        }, 2000);
                    })
                    .fail(err=>{
                        loadEnd();
                        // alert('err');
                        console.log(err);
                        setStatus({
                            type : 1,
                            info : 'An error occurred',
                        })
                        setStatusActive(true);
                        setTimeout(() => {
                            setStatusActive(false);
                        }, 2000);
                    })

                    
                }}>Sign Up</button>
            </div>
        )
    }

    const [page, setPage] = useState(true);
    return(

        <div className="auth auth-base">
            <Status type={status.type} info={status.info} active={statusActive}/>
            <div className='auth-cont'>
                <div className='head'>
                    <button className={`brdls ${page?'active':''}`} onClick={()=>{
                        setPage(true);
                    }}>LOGIN</button>
                    <button className={`brdls mobile-item ${page?'':'active'}`} onClick={()=>{
                        setPage(false);
                    }}>SIGNUP</button>
                </div>
                <div className='body'>
                    {page && <Login />}
                    {!page && <SignUp />}
                </div>
                <div className='head'>
                    <button className={`brdls desktop-item ${page?'':'active'}`} onClick={()=>{
                        setPage(false);
                    }}>SIGNUP</button>
                </div>
            </div>
        </div>

    )
}

export default Auth;