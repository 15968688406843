import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './styles/layout.css';

import Home from './routes/home';
import Spin from './routes/spin';
import Jackpot from './routes/jackpot';
import Account from './routes/account';
import Wallet from './routes/wallet';
import Verify from './routes/verify';

const router = createBrowserRouter([
    {
        path : "/",
        element : <Home />
    },
    {
        path : "spin",
        element : <Spin />
    },
    {
        path : "jackpot",
        element : <Jackpot />
    },
    {
        path : "profile",
        element : <Account />
    },
    {
        path : "wallet",
        element : <Wallet />
    },
    {
        path : "verify-email",
        element : <Verify />
    }
])



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
