import { useEffect, useState } from "react";
import { UserContext } from "../../models/user";
import { loadEnd, loadStart } from "../../utils";

const Withdraw = ({close}) =>{

    const [prg,setPrg] = useState(false);

    const [allowWithdraw, setAllowWithdraw] = useState(false);
    const [amount, setAmount] = useState({
        value : 0.00,
    });

    const [network, setNetwork] = useState(0);
    const [addr, setAddr] = useState();



    let Client = null;

    if(UserContext()){
        Client = UserContext().user;
    }

    useEffect(()=>{
        // console.log(amount);
        if(amount.value >= 1000 && amount.value <= Client.details.balance && network && addr){
            setAllowWithdraw(true);
        }
        else setAllowWithdraw(false);
    },[amount,network,addr])


    return(
        <div className="dep auth-base">
            <div className='auth-cont wi-cont wi tr'>
                <p className="wi-head tr-head">Withdraw</p>
                {!prg && <>
                <p className="tr-pre">
                    Submit wallet address and select the appropriate network.<br/>
                    Selecting the wrong network will result in a permanent loss of the token.<br/>
                    Minimum withdrawal is $1000
                </p>
                <div className="body">
                    <div className="wi-addr-cont">
                        <input className="wi-addr-inp brdls" type="text" placeholder="Wallet address" value={`${addr?addr:''}`} onChange={e=>{
                            setAddr(e.target.value);
                        }}/>
                        <select className="wi-select-token-type brdls" onChange={e=>{
                            setNetwork(parseInt(e.target.value));
                        }}>
                            <option value={0}>--select token network--</option>
                            <option value={1}>USDT Trc20</option>
                            <option value={2}>BTC BTC</option>
                            <option value={3}>BNB Bep20</option>
                        </select>
                    </div>
                    <div className="wi-amt-cont">
                        <p>Amount :$</p>
                        <input type="number" className="brdls" value={`${amount.value}`} onChange={(e)=>{
                        setAmount({
                            value : parseFloat(e.target.value.replace(/[^0-9.-]+/g, "")),
                        })
                    }}/>
                    </div>
                </div>
                <div className="tr-end">
                    <button className="tr-action brdls wi-btn" onClick={()=>{
                        if(Client){
                            let fd = new FormData();
                            fd.append('amount',amount.value);
                            loadStart();
                            Client.withdraw(fd,data=>{
                                loadEnd();
                                Client.init();
                                if(data.status == 0){
                                    setPrg(true);
                                } else {
                                    alert(data.status);
                                }
                            }, err=>{
                                loadEnd();
                                console.log(err);
                            })
                        }
                    }} disabled={!allowWithdraw} >
                        Withdraw
                    </button>
                </div>
                </>}
                {prg && <>
                <p className="wi-modal-txt">Processing Withdrawal ...</p>
                <p className="wi-modal-pre">Do not leave this page till withdrawal is successful</p>
                <p className="wi-modal-pre">Taking too long? <a href="#">Contact Support</a></p>
                </>}
            </div>
        </div>
    )
}

export default Withdraw;