// import { useState, useEffect } from 'react';

// import { UserContext } from '../models/user';


// import Layout from "../components/layout";
// import { Edit } from "../components/svgs";

// import '../styles/account.css';

// const Account = () =>{
//     const [displayID, setDisplayID] = useState('User');
//     let Client = null;

//     const Loader = () =>{
//         if(UserContext()){
//             Client = UserContext().user;
//         }
//         useEffect(()=>{
//             if(Client){
//                 const awaitUserDetails = setInterval(()=>{
//                     if(Client.details){
//                         setDisplayID(Client.details.email);
//                         clearInterval(awaitUserDetails);
//                     }
//                 },100);
//             } 
//         },[]);

//         return(
//             <></>
//         )
//     }

//     return(
//         <Layout>
//             <div className="container">
//                 <Loader />
//                 <div className="ac-head">
//                     <div className="ac-avatar-cont">
//                         <span className="ac-avatar"></span>
//                         <button className="brdls bgls ac-ch-avatar"><Edit /></button>
//                     </div>
//                 </div>
//                 <div className="ac-name-cont">
//                     <p>{displayID}</p>
//                 </div>
//                 <div className="ac-ch-pass-cont">
                    
//                 </div>
//             </div>
//         </Layout>
//     )
// }

// export default Account;

import { useState, useEffect } from 'react';

import { UserContext } from '../models/user';


import Layout from "../components/layout";
import { Edit } from "../components/svgs";

import '../styles/account.css';

const Account = () =>{
    const [displayID, setDisplayID] = useState('User');
    let Client = null;

    const Loader = () =>{
        if(UserContext()){
            Client = UserContext().user;
        }
        useEffect(()=>{
            if(Client){
                const awaitUserDetails = setInterval(()=>{
                    if(Client.details){
                        setDisplayID(Client.details.email);
                        clearInterval(awaitUserDetails);
                    }
                },100);
            } 
        },[]);

        return(
            <></>
        )
    }

    return(
        <Layout>
            <div className="container">
                <Loader />
                <div className="ac-head">
                    <div className="ac-avatar-cont">
                        <span className="ac-avatar"></span>
                        <button className="brdls bgls ac-ch-avatar"><Edit /></button>
                    </div>
                </div>
                <div className="ac-name-cont">
                    <p>{displayID}</p>
                </div>
                <div className="ac-ch-pass-cont">
                    
                </div>
            </div>
        </Layout>
    )
}

export default Account;