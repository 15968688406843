import { useState, useEffect } from 'react';
import $ from 'jquery';

import { UserContext } from '../models/user';

import Layout from "../components/layout";
import { SpinBoard, SpinPointer } from "../components/svgs";

import "../styles/spin.css";
import { loadEnd, loadStart } from '../utils';
const SpinMain = () =>{

    const [inAmount, SetInAmount] = useState(0);
    const [__bal__, setBalance] = useState(0);
    const [positionAmount, openPositionAmount] = useState(0);

    const [allowToOpenPostion, setAllowToOpenPosition] = useState(false)
    let Client = null;

    if(UserContext()){
        Client = UserContext().user;
    }
    useEffect(()=>{
        if(Client){
            const awaitUserBalance = setInterval(()=>{
                if(Client.details){
                    setBalance(Client.details.balance);
                    setSpinsLeft(Client.details.spins);
                    if(Client.details.position != 0){
                        SetInAmount(parseFloat(Client.details.position));
                        allowSpin(true);
                    }
                    clearInterval(awaitUserBalance);
                }
            },100);

        }
    },[]);

    

    const openPosition = () =>{
        if(Client){
            let fd = new FormData;
            fd.append('amount', positionAmount);
            Client.openPosition(fd,data=>{
                console.log(data);
                if(data.status == 0){
                    SetInAmount(parseFloat(data.data.position));
                    Client.init();
                }   
            }, err =>{
                console.log(err);
            })
        }
    }



    const closePosition = async () =>{
        if(Client){
            let fd = new FormData;
            Client.closePosition(fd, async(data)=>{
                console.log(data);
                if(data.status == 0){
                    SetInAmount(0);
                    await Client.init();
                    window.location.reload();
                }
            }, err =>{
                console.log(err);
            });
        }
    }

    const [spinning, setSpin] = useState(false);
    const [spinAngle, setSpinAngle] = useState(0);
    const spinner = $('#spin-cont svg');

    const [canSpin, allowSpin] = useState(false);
    const [spinsLeft, setSpinsLeft] = useState(0);

    useEffect(()=>{
        console.log(spinsLeft);
    },[spinsLeft])


    const generateRandRange = (min, max, place)=>{
        let rand = Math.random() * (max - min) + min;
        return Math.round(rand * place) / place;
    }

    useEffect(()=>{
        const spin = async () =>{
            let _tmpSpinAngle = spinAngle,
            spinRate = generateRandRange(10.0, 40.0, 10),
            decrement = generateRandRange(.05,.10,1000),
            angle;
            const main = async () =>{
                _tmpSpinAngle += spinRate;
                spinRate -= decrement;
                if(spinRate<0){
                    cancelAnimationFrame(animation);
                    setSpinAngle(_tmpSpinAngle % 360);
                    setSpin(false);
                    return;
                }
                angle = _tmpSpinAngle % 360;
                spinner.css('transform',`rotate(${angle}deg)`);
                window.requestAnimationFrame(main);
            }
            const animation = window.requestAnimationFrame(main)
        }
        if(spinning == true  && spinsLeft > 0){
            spin();
        }
    },[spinning]);

    const fail = ()=>{
        // SetInAmount(inAmount * 0.5);
        let fd = new FormData();
        fd.append('amount',0.5);
        loadStart();
        Client.spin(fd,data=>{
            loadEnd();
            console.log(data);
            if(data.status == 0){
                SetInAmount(data.data.position);
            }
            else {
                alert(data.message);
            }
        },err=>{
            loadEnd();
            console.log(err);
        })
        allowSpin(true);
    }

    const win = (x) =>{
        // SetInAmount(inAmount * x);
        let fd = new FormData();
        fd.append('amount',x);
        loadStart();
        Client.spin(fd,data=>{
            loadEnd();
            console.log(data);
            if(data.status == 0){
                SetInAmount(data.data.position);
            }
            else {
                alert(data.message);
            }
        },err=>{
            loadEnd();
            console.log(err);
        })
        allowSpin(true);
    }

    const isIn = (spinAngle) => {
        const angle = spinAngle % 360;
        const normalizedAngle = angle < 0 ? angle + 360 : angle; // Ensure angle is positive
        const section = normalizedAngle % 90;
        return section > 0 && section < 45;
    };

    useEffect(()=>{
        const _sa = spinAngle+2
        if(spinAngle !== 0){
            if(!isIn(_sa)){
                fail();
            } else{
                if(_sa < 45) win(3);
                if(_sa > 90 && _sa < 135) win(2);
                if(_sa > 180 && _sa < 225) win(5);
                if(_sa > 270 && _sa < 315) win(4);
            }
            // Client.init();
            setSpinsLeft(spinsLeft - 1);

        }
        // console.log(_sa);
    }, [spinAngle])


    // ------- this part is for pre entry setter

    const [preActive, setPreActive] = useState(false);
    const [preSetInput, setPreSetInput] = useState({
        value : 0.00,
    });

    const [valid, setValid] = useState(false);

    useEffect(()=>{
        if(0.1 < preSetInput.value && preSetInput.value < __bal__){
            setValid(true);
        }
        else{
            setValid(false);
        }
        
    },[preSetInput]);

    useEffect(()=>{
        if(inAmount <= 0.1){
            setPreActive(true);
        }
        else {
            setPreActive(false);
        }
    },[inAmount]);


    useEffect(()=>{
        if(allowToOpenPostion && positionAmount > 0.1){
            openPosition(positionAmount);
        }
    },[allowToOpenPostion, positionAmount]);


    return(
        <div className="container sp-cont">
            <div className="spin-area">
                <p className='head'>${inAmount.toFixed(2)}</p>
                <div className='spin-display'>
                    <div className='sp-pointer-holder'>
                        <SpinPointer />
                    </div>
                    <div className="spin-cont" id='spin-cont'>
                        <SpinBoard />
                        
                    </div>

                </div>
                <p className='spins-left'>{spinsLeft} LEFT</p>
                <div className="spin-actions">
                    <button className='spin-spin brdls' disabled={!canSpin} onClick={()=>{
                        setSpin(true);
                        allowSpin(false);
                    }}>
                        SPIN 
                    </button>
                    <button className='spin-close brdls' onClick={()=>{
                        closePosition();
                    }}>
                        Close position 
                    </button>
                </div>
            </div>
            {preActive && <div className='sp-set-amount-cont'>
                <p className='head'>Set Spin Entry Amount</p>
                <p className='pre'>Total Balance : {__bal__.toFixed(2)}</p>
                <div className='body'>
                    <input type='number' placeholder='Amount' className='brdls' value={`${preSetInput.value}`} onChange={(e)=>{
                        setPreSetInput({
                            value : parseFloat(e.target.value.replace(/[^0-9.-]+/g, "")),
                        })
                    }}/>
                    <button className='brdls' disabled={!valid} onClick={()=>{
                        openPositionAmount(preSetInput.value);
                        setAllowToOpenPosition(true);
                        allowSpin(true);
                    }}>
                        ENTER
                    </button>
                </div>
            </div>}
        </div>
    )
}

const Spin = () =>{
    return(
        <Layout>
            <SpinMain />
        </Layout>
    )
}

export default Spin;