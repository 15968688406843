import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../../models/user";

import { USD, User } from "../svgs";

const Header = () =>{

    const [balance, setBalance] = useState(0);
    let Client = null;
    if(UserContext()){
        // console.log('meee');
        Client = UserContext().user;
        
    }
    
    useEffect(()=>{
        if(Client){
            const awaitUserBalance = setInterval(()=>{
                if(Client.details){
                    setBalance(Client.details.balance);
                    clearInterval(awaitUserBalance);
                }
            },100);
        }   
    },[]);
    if(Client){
        setInterval(()=>{
            try{
                setBalance(Client.details.balance);
            } catch(err){}
        },5000)
    }

    

    // useEffect(()=>{
    //     if(Client.details){
    //         setBalance(Client.details.balance);
    //     }
    // },[Client]);

    
   
    
    return(
        <header id="header" className="hd">
            <div className="org-id">
                <img src="/assets/images/logo.png" id="logo"/>
                <p><span>SPIN</span><span>BONANZA</span></p>
            </div>
            <div className="user">
                <Link to="/wallet">
                    <span className="wallet">
                        <span><USD /></span>
                        <span>
                            { balance.toFixed(2) }
                            <sup> +0.0%</sup>
                        </span>
                    </span>
                </Link>
                <Link to="/profile">
                    <User />
                </Link>
                
            </div>
        </header>
    )
}



export default Header;