import { Link } from "react-router-dom";

import Layout from "../components/layout";
import '../styles/home.css';

const Home = () =>{
    return(
        <Layout>
            <div className="container">
                <div className="ad-cont">
                    <div className="ad-banner">
                        <img src="/assets/images/welcome.png" />
                    </div>
                </div>
                <div className="home-content hc">
                    <div className="top">
                        <Link className="game spin" to="/spin">
                            <p>SPIN</p>
                        </Link>
                        <Link className="game jack" to="/jackpot">
                            <p>JACKPOT</p>
                        </Link>
                        <Link className="game">
                            <p>MORE GAMES</p>
                        </Link>
                    </div>
                    <div className="base">
                        <Link className="action" to="/wallet">
                            <p>WALLET</p>
                        </Link>
                        <Link className="action" to="/profile">
                            <p>ACCOUNT</p>
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Home;