import $ from 'jquery';

export const logout_user = () =>{
    window.localStorage.clear();
    window.location.href = '/';
}

export const loadStart = () =>{
    $('#loader-container').fadeIn(300)
}

export const loadEnd = () =>{
    $('#loader-container').fadeOut(500);
}