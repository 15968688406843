import $ from 'jquery';
import config from '../../config';
import { logout_user, loadStart, loadEnd } from '../../utils';

class User {
    details = null;
    constructor(userId){
        this.User_key = userId;
        this.init();
    }

    _fetch = async(_url,payload,cb,cbe) =>{
        let token;
        if(this.User_key){
            token = this.User_key;
        }
        $.ajax({
            url : `${config.apiUrl}/${_url}`,
            dataType : 'json',
            method : 'post',
            headers: {
                'Authorization': `Bearer ${this.User_key}`
            },
            data : payload,
            processData : false,
            contentType: false,
            cache: false
        }).done((data) =>{
            cb(data);
        }).fail((err)=>{
            cbe(err);
        });
    }

    init = async() =>{
        loadStart();
        this._fetch('api/v1/app/init',new FormData,data=>{
            if(data.status == 0){
                this.details = data.data;
                this.OpenPosition = {
                   inAmount : 0
                }
            }
            else{
                logout_user();
            }
            loadEnd();
        },err=>{
            // alert('err');
        })
    }

    openPosition= async(fd,cb,cbe) =>{
        this._fetch('api/v1/app/open-position',fd,data=>{
            cb(data);
        }, err=>{
            alert('an error occured');
            cbe(err);
        })
    }

    spin = async (fd,cb,cbe) =>{
        this._fetch('api/v1/app/spin',fd,data=>{
            cb(data);
        }, err=>{
            alert('an error occured');
            cbe(err);
        })
    }

    closePosition = async(fd,cb,cbe) =>{
        this._fetch('api/v1/app/close-position',fd,data=>{
            cb(data);
        }, err=>{
            alert('an error occured');
            cbe(err);
        })
    }

    getWallets = async(cb, cbe) =>{
        loadStart();
        this._fetch('api/v1/app/get-wallets',new FormData(), data=>{
            loadEnd();
            cb(data);
        }, err=>{
            loadEnd();
            cbe(err);
        })
    }

    withdraw = async(fd,cb,cbe) =>{
        this._fetch('api/v1/app/withdraw',fd,data=>{
            cb(data);
        }, err=>{
            alert('an error occured');
            cbe(err);
        })
    }
}


export default User;