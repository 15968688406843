import Header from "./header";

const Main = ({children}) => {
    return(
        <main className="main">
            <Header />
            <div className="under-header"></div>
            <div className="content">
                {children}
            </div>
            
        </main>
    )
}

export default Main;