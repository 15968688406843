import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Home, Logout, Menu, Profile, Spin, Wallet } from "../svgs";
import { logout_user } from "../../utils";


const Nav = () => {
    const [location, setLocation] = useState();
    const [menuOpen, OpenMenu] = useState(false);
    const paths = useLocation().pathname.split('/').filter(Boolean);
    useEffect(()=>{
        switch (paths[0]) {
            case undefined:
            case 'home':
                setLocation(0);
                break;
            case 'spin':
                setLocation(1);
                break;
            case 'wallet':
                setLocation(2);
                break;
            case 'profile':
                setLocation(3);
                break;
            default:
                setLocation(0);
                break;
        }
    })
    
    return(
        <nav className={`${menuOpen?'active':''} nav`}>
            <button id="menu-open-btn" className="brdls mobile-item" onClick={()=>{
                OpenMenu(!menuOpen);
            }}>
                <Menu />
            </button>
            <div className="head">
                <img src="/assets/images/logo.png"/>
            </div>
            <div className="body">
                <Link className={`nav-btn ${location==0?'active':''}`} to="/" onClick={()=>{
                    OpenMenu(!menuOpen);
                }}>
                    <Home color={location==0?'#FFC701':'#3B436B'}/>
                    <span>HOME</span>
                </Link>
                <Link className={`nav-btn ${location==1?'active':''}`} to="/spin" onClick={()=>{
                    OpenMenu(!menuOpen);
                }}>
                    <Spin color={location==1?'#FFC701':'#3B436B'}/>
                    <span>SPIN</span>
                </Link>
                <Link className={`nav-btn ${location==2?'active':''}`} to="/wallet" onClick={()=>{
                    OpenMenu(!menuOpen);
                }}>
                    <Wallet color={location==2?'#FFC701':'#3B436B'}/>
                    <span>WALLET</span>
                </Link>
                <Link className={`nav-btn ${location==3?'active':''}`} to="/profile" onClick={()=>{
                    OpenMenu(!menuOpen);
                }}>
                    <Profile color={location==3?'#FFC701':'#3B436B'}/>
                    <span>ACCOUNT</span>
                </Link>
            </div>

            <div className="end">
                <Link className="nav-btn" onClick={()=>{
                    OpenMenu(!menuOpen);
                    logout_user();
                }}>
                    <Logout />
                </Link>
            </div>
        </nav>
    )
}

export default Nav;