import { useEffect ,useState } from "react";

import { UserContext } from "../models/user";
import Layout from "../components/layout";
import Deposit from "../components/wallet/deposit";
import Withdraw from "../components/wallet/withdraw";


import "../styles/wallet.css";


const Transaction = ({data={desc:'...',date:'...loading',amount:'...'}})=>{
    return(
        <div className="wa-hist-lay wa-hist-entry">
            <p>{ data.desc }</p>
            <p>{ data.date }</p>
            <p>{ data.amount }</p>
        </div>
    )
}

const Content = () =>{
    const [widthdraw, openWithdraw] = useState(false);
    const [deposit, openDeposit] = useState(false);
    const [balance, setBalance] = useState(0);
    const [history, setHistory] = useState(null);
    const [historyOut,setHistoryOut] = useState([])



    let Client = null;

    const Loader = () =>{
        if(UserContext()){
            Client = UserContext().user;
        }
        useEffect(()=>{
            if(Client){
                const awaitUserBalance = setInterval(()=>{
                    if(Client.details){
                        setBalance(Client.details.balance);
                        setHistory(Client.details.transactions);
                        clearInterval(awaitUserBalance);
                    }
                },100);
            } 
        },[])

        return(
            <></>
        )
    }

    useEffect(()=>{
        if(history!=null){
            let le = 0;
            le = history.length >= 4 ? 4 : history.length;
            let _tmp = [];
            for (let i = 0; i < le; i++) {
                _tmp.push(<Transaction data={{
                    desc : history[i].desc,
                    date : history[i].date,
                    amount : history[i].amount.toFixed(2)
                }}/>)
                
            }
            setHistoryOut([_tmp]);
        }
    },[history])

    return(
        <>
        {
            deposit && <Deposit close={()=>{
                openDeposit(false);
            }}/>
        }
        {
            widthdraw && <Withdraw close={()=>{
                openWithdraw(false);
            }}/>
        }
        <div className="container">
            <Loader />
            <div className="wa-cont">
                <div className="wa-bal-cont">
                    <p className="wa-bal">
                        <span>$</span>
                        <span>{balance.toFixed(2)}</span>
                    </p>
                    <p className="wa-trend p">
                        <span>+0.0% </span>
                        <span>past 24hrs</span>
                    </p>
                </div>
                {/* <BalView /> */}
                <div className="wa-actions-cont">
                    <button className="wa-action brdls" onClick={()=>{
                        openDeposit(true);
                    }}>
                        Deposit
                    </button>
                    <button className="wa-action brdls" onClick={()=>{
                        openWithdraw(true);
                    }}>
                        Withdraw
                    </button>
                </div>
                <div className="wa-history">
                    <p className="head">Recents</p>
                    <p className="sep"></p>
                    <div className="wa-hist">
                        <div className="wa-hist-head wa-hist-lay">
                            <p>Description</p>
                            <p>Date</p>
                            <p>Amount</p>
                        </div>
                        {historyOut}
                    </div>
                </div>
            </div>
        </div>
        
        </>
    )
}


const Wallet = () =>{
    return(
        <Layout>
            <Content />
        </Layout>
    )
}

export default Wallet;