import { useState, useEffect } from "react";
import $ from 'jquery';

import config from "../config";

const Verify = () =>{
    const [vState, setVState] = useState("Processing...");
    const [resolved, resolve] = useState(false);

    const params = new URLSearchParams(window.location.search);
    const email = params.get('e');
    const token = params.get('psk');

    useEffect(()=>{
        const verify = async () =>{
            if(email && token){
                let fd = new FormData;
                fd.append('email',email);
                fd.append('verif',token);
                $.ajax({
                    url : `${config.apiUrl}/api/v1/auth/verify-account`,
                    dataType : 'json',
                    method : "POST",
                    data : fd,
                    dataType : 'json',
                    processData : false,
                    contentType: false,
                    cache: false
                }).done(data=>{
                    setVState(data.message);
                    resolve(true);
                    if(data.status == 0 || data.status == 4){
                        setTimeout(()=>{
                            window.location.href = "/"
                        },500)
                    }
                }).fail(err=>{
                    setVState('An Error Occurred');
                    resolve(true);
                    console.log(err);
                })
            }
            else{
                setVState('Invalid verification link')
                resolve(true);

            }
        }
        verify();
    },[])

    return(
        <div className="verify-main">
            <div className="ve-cont">
                {
                    !resolved&&<><p className="text-1">Please wait while we verify your email</p>
                    <p className="text-2">{vState}</p></>
                }
                {
                    resolved&&<p className="text-3">
                        {vState}
                    </p>
                }
            </div>
        </div>
    )
}
export default Verify;