import { UserProvider } from "../../models/user";


import Pane from "./aside";
import Main from "./main";
import Nav from "./nav";
import Auth from "../auth";

import { loadEnd } from "../../utils";

import { Discord } from "../svgs";

let authenticated = false;
if(localStorage.getItem('tin')){
    authenticated = true;
}

const Provider = ({children}) => {
    if(authenticated){
        return(
            <UserProvider userId={localStorage.getItem('tin')}>
                {children}
            </UserProvider>
        )
    }
    else return(
        <>{children}</>
    )
}

const Layout = ({children}) =>{
    loadEnd();
    return(
        <Provider>
            {!authenticated && <Auth />}
            <div id="app">
                <div className="h-top">
                    <div className="icons">
                        <a href="#"><Discord /></a>
                    </div>
                    <p>Spin Bonanza &#169; 2024</p>
                </div>
                <div className="layout">
                    <Nav />
                    <Main>
                        {children}
                    </Main>
                    <Pane />
                </div>
            </div>
        </Provider>
    )
}

export default Layout